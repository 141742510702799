.App {
  text-align: center;
}

/*
.mdl-shadow--2dp {
  box-shadow: none;
}

.firebaseui-info-bar {
  margin-top: 20px;
}

div.mdl-progress::after {
  display: block;
  color: black;
  content: "Authenticating";
  margin: 20px auto;
  text-align: center;
}

.mdl-progress {
  height: 5px;
}

.firebaseui-id-page-callback {
  visibility: hidden !important;
}

.firebaseui-callback-indicator-container {
  visibility: hidden !important;
}

#progressBar {
  visibility: hidden !important;
} */